import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/Layout"
import { StaticImage } from "gatsby-plugin-image"
import "../../assets/css/global.css"
import Company from "../../assets/images/Film-Chronicle-Logo-Tertiary-Light.svg"
import Sponsors from "../../assets/images/gray-hands.svg"
import Judges from "../../assets/images/Peace-Light.svg"
import Rules from "../../assets/images/Medal-Dark.svg"
import Seo from "../../components/Seo"
import LoadMore from "../../assets/images/Load-more-arrow.svg"
import { Helmet } from "react-helmet"

const aboutRules = () => {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8"/>
        <title>Rules and FAQs</title>
        <link rel="canonical" href="https://filmchronicle.com/about/rules" />
      </Helmet>
      <Seo title="Rules &amp; FAQ" description="Learn about how we offer awards, prizes, &amp; all those questions you have will be answered here." image="../../../assets/images/About-Us__Rules-Hero.png" />
      <header className="hero">
        <StaticImage
          src="../../assets/images/About-Us__Rules-Hero.png"
          alt="flour and eggs"
          className="hero-img"
          placeholder="tracedSVG"
          layout="fullWidth"
        ></StaticImage>
        <div className="hero-container">
          <div className="hero-text">
            <h1 style={{marginBottom: "1.5rem"}}>Explaining the Rules</h1>
            <p style={{color: "#fff", fontFamily: "Proxima Nova, sans-serif", fontWeight: "500", textTransform: "none", maxWidth: "40rem"}}>Learn about how we offer awards, prizes, &amp; all those questions you have will be answered here.</p>
          </div>
        </div>
      </header>
      <div className="container-md">
        <div className="grid-cols-4 center">
        <Link to="/about/company">
            <img
              src={Company}
              alt="company icon"
              className="hero-img"
              style={{paddingBottom: "15px"}}
            />
            <h3 style={{textAlign: "center"}}>COMPANY</h3>
          </Link>
        <Link to="/about/judges">
          <img
            src={Judges}
            alt="judges icon"
            className="hero-img"
            style={{fontSize: "100px", paddingBottom: "15px"}}
          />
          <h3 style={{textAlign: "center"}}>JUDGES</h3>
        </Link>
        <Link to="/about/rules">
          <img
            src={Rules}
            alt="rules icon"
            className="hero-img"
            style={{fontSize: "100px", paddingBottom: "15px", margin: "auto"}}
          />
          <h3 style={{textAlign: "center"}}>RULES &amp; FAQs</h3>
          <div style={{paddingTop: "15px",borderBottom: "0.25rem solid red"}}>
              </div>
        </Link>
        <Link to="/about/sponsors">
          <img
            src={Sponsors}
            alt="sponsors icon"
            className="hero-img"
            style={{fontSize: "100px", paddingBottom: "15px"}}
          />
          <h3 style={{textAlign: "center"}}>SPONSORS</h3>
        </Link>
        </div>
      </div>
        <div className="container">
            <div className="grid-cols-4 grid-cols-1">
              <div className="col-span-1">
                <p style={{fontWeight: "bold"}}>Awards &amp; Prizes</p>
              </div>
              <div className="col-span-3">
                <p>1. The monthly winner will be awarded a copy of Final Draft 12. All submitters will get a 35% discount code for Final Draft 12. Final Draft 12 Educational is also available for all current film students. Thank you to <a href="https://www.finaldraft.com" target="_blank" rel="noopener">Final Draft</a>!</p>
                <p>2. The top film will have its own individual page, marked with a personal interview and bio of the student Filmmaker responsible for the work.</p>
                <p>3. The top six films of the Monthly Festival will be presented on the front page of our website till the end of the next month.</p>
                <p>4. All films submitted to the festival will transition to our film library for extended view after the festival is over.</p>
                <p>5. All of the top six films will be marketed on our social media as part of further exposure for the filmmakers.</p>
                <p>6. The purpose of this festival is to help cultivate a Student Film Community. Regardless if you win or not, each student film is important and should be supported. This is where Film Chronicle comes in. Students can share their film with this burgeoning community, and likewise use it to network and reach out to other like-minded filmmakers in your respective area outside of your university.</p>
              </div>
            </div>
            <hr style={{width: "100%", backgroundColor: "#B2B8BF", height: "3px", display: "block"}}/>
            <div className="grid-cols-4 grid-cols-1 pt-50">
              <div className="col-span-1">
                <p style={{fontWeight: "bold"}}>FAQs</p>
              </div>
              <div className="col-span-3">
                <p style={{fontWeight: "bold"}}>1. What is the difference between the Festival and the Library?</p>
                <p style={{marginBottom: "25px"}}>For the Film Festival, only current film students can submit their recent work and contend for monthly awards and recognition. For the Film Library, any and all past film students, regardless of year of graduation, can submit and share their work that they made during their time at university. </p>
                <p style={{fontWeight: "bold"}}>2. What is the difference between our Film Festival (Film Chronicle), and a traditional Film Festival? </p>
                <p style={{marginBottom: "25px"}}>The difference between our festival, and the more traditionally structured festivals is that this is entirely audience driven. We let the community watch the submissions and decide their champion. But in order to ensure fair competition, a Jury of unbiased and capable film students will take the baton from the Audience’s nominations, and vote on the Best Film of the Month. This way, audience participation is higher than most traditional festivals, and likewise, a fair competition is maintained through a Jury’s check.</p>
                <p style={{fontWeight: "bold"}}>3. What type of films do you accept into the festival?</p>
                <p style={{marginBottom: "25px"}}>We accept all films - Dramas, Comedies, Horror, Experimental, etc. We’ll take it all. However, please make sure the films are not minor class exercises, and subject matter is morally dignified for public display.</p>
                <p style={{fontWeight: "bold"}}>4. Do you accept documentaries? </p>
                <p style={{marginBottom: "25px"}}>Yes.</p>
                <p style={{fontWeight: "bold"}}>5. Who is the Jury?</p>
                <p style={{marginBottom: "25px"}}>They are upperclassmen from Film Colleges across the country, with no prior affiliations or self-interest. The Jury is designed to check potential audience bias, and help maintain a fair competition. </p>
                <p style={{fontWeight: "bold"}}>6. I am a high school student. How can I get involved with Film Chronicle?</p>
                <p style={{marginBottom: "25px"}}>Right now, the festival is only limited to University Students. However, the Library is open to public view. If you are curious to see the film pedigree of a specific college, perhaps one that you are applying to, then the film library is a perfect place for you. There is content to be watched and studied for current students, and future students.</p>
                <p style={{fontWeight: "bold"}}>7. I’ve recently graduated from University. Can I submit my film to the Festival and/or the Library?</p>
                <p style={{marginBottom: "25px"}}>Yes, if the film you are submitting was made while you were an enrolled student, and it fits the time parameters of the festival, then you are eligible to submit to both the Festival and the Library.</p>
                <p style={{fontWeight: "bold"}}>8. I am studying at a University outside the US. Can I submit my film?</p>
                <p style={{marginBottom: "25px"}}>Currently we can only accept films from students in the United States. However, in the future we plan to open this up globally that will allow ALL film students to participate.</p>
                <p style={{fontWeight: "bold"}}>9. Is there any submission fee for the Library or/and Festival?</p>
                <p style={{marginBottom: "25px"}}>No, everything is free of charge! Our goal is to build a community for aspiring filmmakers. And knowing the full scope of the monetary burdens that students have to carry in order to attend university, we realized that the most effective way of actualizing this goal would be through open and free engagement.</p>
                <p style={{fontWeight: "bold"}}>10. Are there any specific formats to submit the film?</p>
                <p style={{marginBottom: "25px"}}>No, the film should be provided through a YouTube or Vimeo link. The format that you choose to upload your film on YouTube and Vimeo is entirely up to you.</p>
              </div>
            </div>
            <hr style={{width: "100%", backgroundColor: "#B2B8BF", height: "3px", display: "block"}}/>
            <div className="grid-cols-4 grid-cols-1 pt-50">
              <div className="col-span-1">
                <p style={{fontWeight: "bold"}}>Policy &amp; Terms</p>
              </div>
              <div className="col-span-3">
                <p>Film Chronicle accepts all types of films covering nearly all types of genres and topics. The Festival Program is entirely unbiased and does not favor any particular participant or background. We are all-accepting under the rules of proper etiquette and moral ethics. However, currently we can only accept films from students in the United States, but in the near future, we do plan on opening the program globally. Our film jury is composed of like-minded students who are currently studying or have studied film production/entertainment at their respective University. </p>
              </div>
            </div>
            <hr style={{width: "100%", backgroundColor: "#B2B8BF", height: "3px", display: "block"}}/>
            <div className="grid-cols-4 grid-cols-1 pt-50">
              <div className="col-span-1">
                <p style={{fontWeight: "bold"}}>Rules</p>
              </div>
              <div className="col-span-3">
                <p>1. Submitted films must be up to 45 minutes for consideration.</p>
                <p>2. Only active students enrolled in a particular university or educational program can apply.</p>
                <p>3. Please fill out the Festival Submission Form part of the submission. The form will help provide credence to your position as a student of a particular university.</p>
                <p>4. In the Festival Submission Form, applicants must provide a working Vimeo or Youtube link that contains the proper version of the film they would like us to consider. If the link is private and/or password secured, please add the password details in the form. If the link is faulty, and/or does not contain a video file for us to review and watch, your submission will not be considered.</p>
                <p>5. We consider films of any language, from Students in the United States. If your film is not in English, make sure it has English subtitles where necessary.</p>
                <p>6. Accepted filmmakers must own rights to their film and all its components by the time of the festival.</p>
              </div>
            </div>
            <hr style={{width: "100%", backgroundColor: "#B2B8BF", height: "3px", display: "block"}}/>
            <div className="grid-cols-4 grid-cols-1 pt-50">
              <div className="col-span-1">
                <p style={{fontWeight: "bold"}}>Rights</p>
              </div>
              <div className="col-span-3">
                <p>1. Filmmakers must own rights to their film and all its components by the time of the festival.</p>
                <p>2. By submitting your film to our festival you agree that your piece of work can be uploaded/embedded to our website for public showcase.</p>
                <p>3. By submitting, the filmmaker allows the Students Film Festival to create a page and publicity for their film without infringing copyright of any kind of form.</p>
                <p>4. After submission of the film and insertion into our Film archive, you give us permission to present your work through our distribution campaign, coupled with advertisements to help finance the festival program and marketing of your film.</p>
              </div>
            </div>
            <hr style={{width: "100%", backgroundColor: "#B2B8BF", height: "3px", display: "block"}}/>
            <div className="grid-cols-4 grid-cols-1 pt-50">
              <div className="col-span-1">
                <p style={{fontWeight: "bold"}}>How does it work?</p>
              </div>
              <div className="col-span-3">
                <p style={{fontWeight: "bold"}}>Phase 1</p>
                <p style={{marginBottom: "25px"}}>The festival will be open for submission. Participants can submit their films to the following month’s festival. The window will close on the 3 days before the last day of the month, 11:59 pm PST promptly.</p>
                <p style={{fontWeight: "bold"}}>Phase 2 </p>
                <p style={{marginBottom: "25px"}}>The films will go live on our website for public view. Films may or may not be divided into groups, depending on the volume of submissions. This is designed so that each film may get its fair exposure whilst providing an easy browsing experience for the viewers.
In this time period, the audience will have the opportunity to watch the films and vote on their favorite. The votes will be tallied, and the top six films with the highest votes will be announced at the end of the time period.</p>
                <p style={{fontWeight: "bold"}}>Phase 3</p>
                <p style={{marginBottom: "25px"}}>The top six films will then be watched by a Jury of Judges. The Judges will decide on the TOP FILM, which will be announced in the following month. Awards will be allocated.</p>
              </div>
            </div>
          </div>
          <div className="container">
        <div className="grid-cols-3">
          <p className="col-span-3" style={{textAlign: "center", marginBottom: "4rem", display: "none"}}>No more results</p>
            <a style={{gridColumnStart: "6"}} href="#top">
              <img
                src={LoadMore}
                alt="flour and eggs"
                placeholder="tracedSVG"
                layout="constrained"
                // quality="90"
              />
            </a>
        </div>
      </div>
    </Layout>
  )
}

export default aboutRules
